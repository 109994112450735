/* eslint-disable no-console */
import produce from "immer";
import { combineUrlSegments, prependSlash, getToAsPieces } from "../utils";
import createKey from "../utils/createKey";
let count = 0;
let lastOp = "";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const guard = (op, ...log) => {
    if (op !== lastOp) {
        lastOp = op;
        count = 0;
        return false;
    }
    if (count < 1000) {
        // eslint-disable-next-line no-plusplus
        count++;
        return false;
    }
    console.warn("An issue in the react-native-url-router has caused an infinite loop - something is broken.", op, log);
    return true;
};
const reccurentGetLocationFromHistory = (history, pathPrefix = "/") => {
    if (guard("reccurentGetUrlFromHistory", pathPrefix))
        return null;
    const nextPrefixObject = history.segments[pathPrefix];
    if (!nextPrefixObject) {
        console.warn(`prefix not found in nested history for path ${pathPrefix}`);
        return null;
    }
    const segment = history.segments[pathPrefix].segments[nextPrefixObject.index];
    if (!segment) {
        console.warn("prefix index does not point at a correct segment of history", pathPrefix, nextPrefixObject.index);
        return null;
    }
    if (segment.type === "leaf") {
        // we are at a feaf point, so the current URL ends at this segment.
        return { ...segment, pathname: "" };
    }
    const nextPrefix = combineUrlSegments(pathPrefix, segment.pathnamePart);
    const followingSegment = reccurentGetLocationFromHistory(history, nextPrefix);
    return {
        ...followingSegment,
        key: `${segment.key}/${followingSegment.key}`,
        pathname: prependSlash(combineUrlSegments(segment.pathnamePart, followingSegment.pathname)),
    };
};
// export const getCurrentUrlFromHistory = (history: NestedHistory) => {
//   const url = combineUrlSegments(
//     "/",
//     reccurentGetUrlFromHistory(history).pathname
//   );
//   return url;
// };
export const getLocationFromHistory = (history) => {
    // QUESTION: we might need to prepend "/"
    return reccurentGetLocationFromHistory(history);
};
export const getHistoryForPrefix = (history, prefix, key = "") => {
    const root = history.segments[prefix];
    if (guard("getHistoryForPrefix", prefix))
        return [];
    if (!root)
        return [];
    const successorHistory = root.segments
        .slice(0, root.index + 1)
        .flatMap((segment) => segment.type === "leaf"
        ? [{ ...segment, pathname: prefix, key: `${key}/${segment.key}` }]
        : getHistoryForPrefix(history, combineUrlSegments(prefix, segment.pathnamePart), `${key}/${segment.key}`));
    return successorHistory;
};
export const getHistoryWithIndexesForPrefix = (history, prefix, parentPrefixIndexes, key = "") => {
    if (guard("getHistoryWithIndexesForPrefix", prefix))
        return [];
    const root = history.segments[prefix];
    if (!root)
        return [];
    const successorHistory = root.segments
        .slice(0, root.index + 1)
        .flatMap((segment, idx) => segment.type === "leaf"
        ? [
            {
                location: {
                    ...segment,
                    pathname: prefix,
                    key: `${key}/${segment.key}`,
                },
                prefixIndexes: {
                    ...parentPrefixIndexes,
                    [prefix]: idx,
                },
            },
        ]
        : getHistoryWithIndexesForPrefix(history, combineUrlSegments(prefix, segment.pathnamePart), {
            ...parentPrefixIndexes,
            [prefix]: idx,
        }, `${key}/${segment.key}`));
    return successorHistory;
};
export const applyPrefixIndexesToHistory = (history, prefixIndexes) => {
    const newHistory = produce(history, (draft) => {
        Object.keys(prefixIndexes).forEach((prefix) => {
            draft.segments[prefix].index = prefixIndexes[prefix];
        });
    });
    return newHistory;
};
const getAccessibleKeys = (history, prefix = "/") => {
    if (guard("getAccessibleKeys", prefix))
        return [];
    return [
        prefix,
        ...(history.segments[prefix] || { segments: [] }).segments.flatMap((segment) => segment.type === "branch"
            ? getAccessibleKeys(history, combineUrlSegments(prefix, segment.pathnamePart))
            : []),
    ];
};
export const resetPrefix = (history, prefix) => {
    return produce(history, (draft) => {
        draft.segments[prependSlash(prefix)].index = 0;
        draft.segments[prependSlash(prefix)].segments = [
            { type: "leaf", search: "", key: createKey(), state: null, hash: "" },
        ];
    });
};
// removes prefix history and leaves just a leaf
export const removePrefix = (history, prefix) => {
    return produce(history, (draft) => {
        delete draft.segments[prefix];
        const parentSegment = draft.segments[prependSlash(prefix.split("/").slice(0, -1).join("/"))];
        if (!parentSegment)
            return;
        const lastElementInHistoryAfterFilter = parentSegment.segments
            .slice(0, parentSegment.index)
            .reverse()
            .find((s) => s.type === "leaf" || s.pathnamePart !== prefix.split("/").at(-1));
        parentSegment.segments = parentSegment?.segments?.filter((s) => s.type === "leaf" || s.pathnamePart !== prefix.split("/").at(-1));
        parentSegment.index = parentSegment.segments.indexOf(lastElementInHistoryAfterFilter);
    });
};
// removes the prefix and all branches that pointed to it
const removeUnreachablePaths = ({ segments, ...rest }) => {
    return { segments, ...rest }; // temp disable
    const accessibleKeys = getAccessibleKeys({ segments });
    return {
        segments: {
            "/": segments["/"],
            ...Object.fromEntries(accessibleKeys.map((k) => [k, segments[k]]).filter((f) => !!f[1])),
        },
        ...rest,
    };
};
export const pushLocationToHistory = (history, to, replace = false, state = null) => {
    if (guard("pushLocationToHistory", to))
        return history;
    const { pathname, ...leafSegment } = getToAsPieces(to);
    const newUrlSegments = [
        ...pathname
            .replace(/(\*|\/)$/, "")
            .split("/")
            .filter((f) => !!f), // url contains empty string, fix!
    ];
    const newHistory = produce(history, (draft) => {
        [...newUrlSegments, null].forEach((newSegment, newUrlSegmentIndex) => {
            const prefix = combineUrlSegments("/", ...newUrlSegments.slice(0, newUrlSegmentIndex));
            if (!draft.segments[prefix]) {
                draft.segments[prefix] = {
                    index: -1,
                    segments: [],
                };
            }
            const currentSegment = draft.segments[prefix].segments[draft.segments[prefix].index];
            if (newSegment) {
                if (!currentSegment ||
                    currentSegment.type !== "branch" ||
                    currentSegment.pathnamePart !== newSegment) {
                    draft.segments[prefix].segments = [
                        ...draft.segments[prefix].segments.slice(0, draft.segments[prefix].index + (replace ? 0 : 1)),
                        {
                            pathnamePart: newSegment,
                            type: "branch",
                            key: createKey(),
                        },
                    ];
                    draft.segments[prefix].index =
                        draft.segments[prefix].segments.length - 1;
                }
                // this is the final segment
            }
            else if (!pathname.endsWith("*")) {
                if (!currentSegment ||
                    currentSegment.type !== "leaf" ||
                    // states are always considered different
                    (!currentSegment.state && !!state) ||
                    (!!currentSegment.state && !state) ||
                    (!!currentSegment.state && !!state) ||
                    currentSegment.search !== (leafSegment.search || "") ||
                    currentSegment.hash !== (leafSegment.hash || "")) {
                    draft.segments[prefix].segments = [
                        ...draft.segments[prefix].segments.slice(0, draft.segments[prefix].index + (replace ? 0 : 1)),
                        {
                            key: createKey(),
                            state,
                            type: "leaf",
                            search: leafSegment.search || "",
                            hash: leafSegment.hash || "",
                        },
                    ];
                    draft.segments[prefix].index =
                        draft.segments[prefix].segments.length - 1;
                }
            }
            else if (draft.segments[prefix].index === -1) {
                draft.segments[prefix].segments = [
                    {
                        key: createKey(),
                        state,
                        search: leafSegment.search || "",
                        hash: leafSegment.hash || "",
                        type: "leaf",
                    },
                ];
                draft.segments[prefix].index = 0;
            }
        });
    });
    return removeUnreachablePaths(newHistory);
};
// check if the onPath is ever used
const goRecursive = (history, { onPath, direction, }) => {
    if (guard("goRecursive", onPath))
        return { handled: false, history };
    // we are at a $ point
    // we are going back in history up to a point where we can subtract an index without leaving it at -1 (there are at last two items on the stack)
    const url = getLocationFromHistory(history).pathname;
    const path = onPath?.startsWith("/")
        ? onPath
        : combineUrlSegments("/", onPath || url || undefined);
    const prefixes = history.segments[path];
    if (!prefixes) {
        return goRecursive(history, {
            onPath: combineUrlSegments(...path.split("/").slice(0, -1)),
            direction,
        });
    }
    // if undo can be performed at the current level
    if (direction === "back"
        ? history.segments[path].index <= 0
        : history.segments[path].index >=
            history.segments[path].segments.length - 1) {
        if (path === "/") {
            return { history, handled: false };
        }
        return goRecursive(history, {
            onPath: combineUrlSegments(...path.split("/").slice(0, -1)),
            direction,
        });
    }
    const newHistory = produce(history, (draft) => {
        draft.segments[path].index += direction === "back" ? -1 : 1;
    });
    return { history: newHistory, handled: true };
};
export const go = (history, config) => {
    if (guard("go", config))
        return { handled: false, history };
    let i = config?.count || 1;
    let lastResult = { handled: true, history };
    while (i > 0 && lastResult.handled) {
        lastResult = goRecursive(history, config || { direction: "back" });
        i -= 1;
    }
    return {
        handled: lastResult.handled,
        history: removeUnreachablePaths(lastResult.history),
    };
};
export const defaultNestedHistory = {
    segments: {
        "/": {
            index: 0,
            segments: [
                { hash: "", search: "", type: "leaf", key: "default", state: {} },
            ],
        },
    },
};
export const getInitialHistoryForPath = (pathname) => {
    return pushLocationToHistory(defaultNestedHistory, { pathname }, true);
};
