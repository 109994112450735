import { useCallback, useState } from "react";
import { Platform } from "react-native";
import { prependSlash } from "../utils";
import useWebIntegration from "../utils/useWebIntegration.web";
import { getLocationFromHistory, pushLocationToHistory, getHistoryForPrefix, go, getHistoryWithIndexesForPrefix, applyPrefixIndexesToHistory, removePrefix, resetPrefix, defaultNestedHistory, getInitialHistoryForPath, } from "./nativeHistory";
const useWebLocation = () => {
    if (Platform.OS === "web") {
        return window.location;
    }
    return null;
};
const useNestedHistory = ({ initialHistory, attachWebURLOn = "", }) => {
    const location = useWebLocation();
    const [history, setHistory] = useState(location?.pathname && location.pathname !== "/"
        ? getInitialHistoryForPath(prependSlash(`${attachWebURLOn}${location.pathname}`))
        : initialHistory || defaultNestedHistory);
    const attemptGo = useCallback((config) => {
        const goResult = go(history, config);
        if (goResult.handled)
            setHistory(() => goResult.history);
        return goResult.handled;
    }, [history]);
    const { navigate: webNavigate } = useWebIntegration({
        setHistory,
        go: attemptGo,
        attachWebURLOn,
    });
    const getNavigateAction = (replace) => (to, state) => {
        setHistory((h) => pushLocationToHistory(h, to, replace, state));
        webNavigate(replace, getHistoryForPrefix(pushLocationToHistory(history, to, replace, state), attachWebURLOn || "/").at(-1), state);
    };
    return {
        location: getLocationFromHistory(history),
        history,
        go: (config) => {
            return attemptGo(config);
        },
        push: getNavigateAction(false),
        replace: getNavigateAction(true),
        removePrefix: (prefix) => setHistory((h) => removePrefix(h, prefix)),
        resetPrefix: (prefix) => setHistory((h) => resetPrefix(h, prefix)),
        applyPrefixIndexesToHistory: (prefixIndexes) => setHistory((h) => applyPrefixIndexesToHistory(h, prefixIndexes)),
        getHistoryForPrefix: (prefix) => getHistoryForPrefix(history, prefix),
        getHistoryWithIndexesForPrefix: (prefix) => getHistoryWithIndexesForPrefix(history, prefix, {}),
    };
};
export default useNestedHistory;
