export const last = (a) => {
    if (!a)
        return undefined;
    return a[a.length - 1];
};
export const combineUrlSegments = (...urls) => {
    // remove all multiple slashes, remove * or / from the end
    const path = urls.join("/").replace(/\/+/g, "/");
    if (path === "")
        return "/";
    if (path !== "/") {
        return path.replace(/(\*|\/)$/, "");
    }
    return path;
};
export const prependSlash = (url) => {
    if (url.endsWith("/")) {
        url = url.slice(0, -1);
    }
    if (url.startsWith("/"))
        return url;
    return `/${url}`;
};
export const surroundSlash = (url) => {
    if (url.startsWith("/") && url.endsWith("/"))
        return url;
    if (url.startsWith("/"))
        return `${url}/`;
    if (url.endsWith("/"))
        return `/${url}`;
    return `/${url}/`;
};
export const uniqueBy = (array, key) => {
    const result = [];
    const map = new Map();
    // eslint-disable-next-line no-restricted-syntax
    for (const item of array) {
        if (!map.has(key(item))) {
            map.set(key(item), true); // set any value to Map
            result.push(item);
        }
    }
    return result;
};
export const uniqueByIfConsequtive = (array, key) => {
    const result = [];
    let lastItemKey;
    // eslint-disable-next-line no-restricted-syntax
    for (const item of array) {
        if (lastItemKey !== key(item)) {
            lastItemKey = key(item); // set any value to Map
            result.push(item);
        }
    }
    return result;
};
export const getToAsPieces = (to) => {
    if (typeof to !== "string") {
        return to;
    }
    const url = new URL(to);
    return url;
};
export const getToPiecesAsString = (to) => {
    if (typeof to === "string") {
        return to;
    }
    return `${to.pathname}${to.search}${to.hash}`;
};
