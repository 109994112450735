import React from "react";
import { Pressable } from "react-native";
import { useNavigate } from "react-router";
const Link = ({ children, to, back, style, state, replace, onPress }) => {
    const navigate = useNavigate();
    return (<Pressable style={({ pressed }) => (pressed ? [{ opacity: 0.85 }, style] : style)} onPress={onPress ||
            (() => (back ? navigate(-1) : navigate(to || "", { state, replace })))}>
      {children}
    </Pressable>);
};
export default Link;
