/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useMemo } from "react";
import { BackHandler } from "react-native";
import { Router } from "react-router";
import useNativeHistory from "../history/useNativeHistory";
const NativeRouterContext = React.createContext({
    history: {
        segments: {
            "/": {
                index: 0,
                segments: [
                    {
                        hash: "",
                        search: "",
                        type: "leaf",
                        key: "default",
                        state: {},
                    },
                ],
            },
        },
    },
    go: () => { },
    getHistoryForPrefix: () => [],
    removePrefix: () => { },
    resetPrefix: () => { },
    getHistoryWithIndexesForPrefix: () => [],
    applyPrefixIndexesToHistory: () => { },
});
export const useNestedHistoryContext = () => useContext(NativeRouterContext);
const NativeRouter = ({ children, initialHistory, attachWebURLOn }) => {
    const { location, go, push, replace, history, getHistoryForPrefix, getHistoryWithIndexesForPrefix, applyPrefixIndexesToHistory, removePrefix, resetPrefix, } = useNativeHistory({ initialHistory, attachWebURLOn });
    React.useEffect(() => {
        const subscription = BackHandler.addEventListener("hardwareBackPress", () => go());
        return () => subscription.remove();
    }, [go]);
    const contextValue = useMemo(() => ({
        history,
        go,
        getHistoryForPrefix,
        getHistoryWithIndexesForPrefix,
        applyPrefixIndexesToHistory,
        removePrefix,
        resetPrefix,
    }), [
        applyPrefixIndexesToHistory,
        getHistoryForPrefix,
        getHistoryWithIndexesForPrefix,
        go,
        history,
        removePrefix,
        resetPrefix,
    ]);
    return (<NativeRouterContext.Provider value={contextValue}>
      <Router location={location} navigator={{
            go: (delta) => {
                go({
                    count: Math.abs(delta),
                    direction: delta < 0 ? "back" : "forward",
                });
            },
            push,
            replace,
            createHref: () => {
                // eslint-disable-next-line no-console
                console.warn("not supported yet");
                return "";
            },
        }}>
        {children}
      </Router>
    </NativeRouterContext.Provider>);
};
export default NativeRouter;
